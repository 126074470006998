<app-sidebar></app-sidebar>
<main [ngClass]="{ 'main-collapsed': isCollapsed }">
  <!-- <app-header></app-header> -->
  <div class="col-lg-12 page-header-wrapper clearfix">
    <div class="col-lg-6 breacrumbs-wrapper float-left">
      <span class="page-title">Team</span>
    </div>
    <div class="col-lg-6 action-wrapper float-left text-right">
      <!-- <button
        class="btn t-primary-dark"
        (click)="openTeamModal(teamContent, 'ADD')"
      >
        <i class="fa fa-plus mr-1"></i> Add New Member
      </button> -->
    </div>
  </div>
  <div class="col-lg-12 main-container clearfix">
    <!-- Client Table Begins -->
    <!-- <app-empty-state></app-empty-state> -->
    <div class="t-card">
      <div class="col-lg-12 d-flex justify-content-between flex-row filters">
        <div class="col-lg-4 input-group pl-0 filters__search">
          <input type="search" class="form-control" placeholder="Search" />
        </div>
      </div>
      <table class="table table-responsive-lg table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email</th>
            <th scope="col">Skype ID</th>
            <th scope="col">Github Username</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let client of ['1', '2', '3', '4']">
              <td>John Doe</td>
              <td>+911234567890</td>
              <td>john@doe.com</td>
              <td>johndoe</td>
              <td>john_doe</td>
              <td>
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-sm btn-dark" ngbDropdownToggle>
                    <i class="fa fa-edit"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="Product Actions">
                    <button
                      ngbDropdownItem
                      (click)="openTeamModal(teamContent, 'EDIT')"
                    >
                      Edit
                    </button>
                    <button ngbDropdownItem>Archieve</button>
                    <button ngbDropdownItem>Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!-- Client Table Ends -->
  </div>
</main>

<!-- Team Modal Begins -->
<ng-template #teamContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="!isEdit">
      Add new member
    </h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="isEdit">
      Update member
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="createTeamMemberForm">
    <div class="modal-body">
      <div class="form-group col-lg-6 float-left">
        <label>Name</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-user"></i></span>
          </div>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            required
          />
        </div>
        <field-errors
          [fieldName]="'name'"
          [formGroup]="teamMemberFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group col-lg-6 float-left">
        <label>Mobile</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-phone-alt"></i
            ></span>
          </div>
          <input
            type="tel"
            formControlName="mobile"
            class="form-control"
            required
          />
        </div>

        <field-errors
          [fieldName]="'mobile'"
          [formGroup]="teamMemberFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group col-lg-6 float-left">
        <label>Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-envelope"></i></span>
          </div>
          <input type="email" class="form-control" required />
        </div>
        <field-errors
          [fieldName]="'email'"
          [formGroup]="teamMemberFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group col-lg-6 float-left">
        <label>Password</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fab fa-lock"></i></span>
          </div>
          <input
            type="password"
            formControlName="password"
            class="form-control"
            required
          />
        </div>
        <field-errors
          [fieldName]="'password'"
          [formGroup]="teamMemberFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group col-lg-6 float-left">
        <label>Github Username</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fab fa-github"></i></span>
          </div>
          <input type="text" class="form-control" required />
        </div>
      </div>
      <field-errors
        [fieldName]="'gitUserName'"
        [formGroup]="teamMemberFG"
        [getErrorMessage]="getErrorMessage.bind(this)"
      ></field-errors>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="addMember()"
      *ngIf="!isEdit"
    >
      CREATE
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="editMember()"
      *ngIf="isEdit"
    >
      UPDATE
    </button>
  </div>
</ng-template>
<!-- Team Modal Ends -->
