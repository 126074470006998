import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { ProjectsComponent } from './projects/projects.component';
import { BoardComponent } from './board/board.component';
import { TimeManagementComponent } from './time-management/time-management.component';
import { FinancesComponent } from './finances/finances.component';
import { ReportsComponent } from './reports/reports.component';
import { FilesComponent } from './files/files.component';
import { ClientsComponent } from './clients/clients.component';
import { CustomizationsComponent } from './customizations/customizations.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'clients',
    component: ClientsComponent
  },
  {
    path: 'projects',
    component: ProjectsComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'board',
    component: BoardComponent
  },
  {
    path: 'time-management',
    component: TimeManagementComponent
  },
  {
    path: 'finances',
    component: FinancesComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'files',
    component: FilesComponent
  },
  {
    path: 'customizations',
    component: CustomizationsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
