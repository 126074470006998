<app-sidebar></app-sidebar>
<main [ngClass]="{'main-collapsed': isCollapsed}">
    <!-- <app-header></app-header> -->
    <div class="col-lg-12 page-header-wrapper clearfix">
        <div class="col-lg-6 breacrumbs-wrapper float-left">
            <span class="page-title">Board</span>
        </div>
        <div class="col-lg-6 action-wrapper float-left text-right">
            <button class="btn t-primary-dark">
            <!-- <button class="btn t-primary-dark" (click)="openEntryModal(entryContent, 'ADD')"> -->
                <i class="fa fa-plus mr-1"></i> Add New Item
            </button>
        </div>
    </div>
    <div class="col-lg-12 main-container clearfix">
        <!-- Filters Begins -->
        <div class="t-card">
            <div class="col-lg-12 d-flex justify-content-between flex-row filters">
                <div class="col-lg-4 input-group pl-0 filters__search">
                    <input type="search" class="form-control" placeholder="Search">
                </div>
                <div class="col-lg-2 filters__dropdown--search">
                    <select class="form-control">
                        <optgroup label="Choose Project">
                            <option value="">All Projects</option>
                            <option value="">Project 1</option>
                            <option value="">Project 2</option>
                        </optgroup>
                    </select>
                </div>
                <div class="col-lg-2 filters__dropdown--search">
                    <select class="form-control">
                        <optgroup label="Choose Epic">
                            <option value="">All Epics</option>
                            <option value="">Epic 1</option>
                            <option value="">Epic 2</option>
                        </optgroup>
                    </select>
                </div>
                <div class="col-lg-2 filters__dropdown--search">
                    <select class="form-control">
                        <optgroup label="Choose Team Member">
                            <option value="">All Members</option>
                            <option value="">John Doe</option>
                            <option value="">Jim Seth</option>
                        </optgroup>
                    </select>
                </div>
                <div class="col-lg-2 filters__dropdown--search">
                    <select class="form-control">
                        <optgroup label="Choose Status">
                            <option value="">All Items</option>
                            <option value="">New</option>
                            <option value="">Active</option>
                            <option value="">In Progress</option>
                            <option value="">Completed</option>
                            <option value="">Closed</option>
                            <option value="">Archieved</option>
                        </optgroup>
                    </select>
                </div>
            </div>
        </div>
        <!-- Filters Ends -->

        <!-- Board Begins -->
        <div class="board col-lg-12" cdkDropListGroup>
            <!-- Single List Group Begins -->
            <div class="col-lg-3 list-type-container">
                <h2 class="heading">
                    To do
                    <span class="count-badge badge badge-secondary">
                        4
                    </span>
                    <span class="add-btn">
                        <button class="btn btn-sm btn-outline-dark">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                </h2>
                <div
                cdkDropList
                [cdkDropListData]="todo"
                class="list-items-group"
                (cdkDropListDropped)="drop($event)">
                    <div class="list-item" *ngFor="let item of todo" cdkDrag cdkDragBoundary=".board">
                        <div class="title">
                            {{item}} - Title of the task goes here
                        </div>
                        <div class="assignee">
                            <img src="../../assets/icons/user.png" alt="">
                            <span class="assignee__name">John Doe</span>
                        </div>
                        <div class="action">
                            <i class="fas fa-ellipsis-h"></i>
                        </div>

                    </div>
                </div>
            </div>
            <!-- Single List Group Ends -->
            
            <div class="col-lg-3 list-type-container">
                <h2 class="heading">
                    In Progress
                    <span class="count-badge badge badge-info">
                        4
                    </span>
                    <span class="add-btn">
                        <button class="btn btn-sm btn-outline-dark">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                </h2>
                <div
                cdkDropList
                [cdkDropListData]="inprogress"
                class="list-items-group"
                (cdkDropListDropped)="drop($event)">
                    <div class="list-item" *ngFor="let item of inprogress" cdkDrag cdkDragBoundary=".board">
                        <div class="title">
                            {{item}} - Title of the task goes here
                        </div>
                        <div class="assignee">
                            <img src="../../assets/icons/user.png" alt="">
                            <span class="assignee__name">John Doe</span>
                        </div>
                        <div class="action">
                            <i class="fas fa-ellipsis-h"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 list-type-container">
                <h2 class="heading">
                    Completed
                    <span class="count-badge badge badge-success">
                        4
                    </span>
                    <span class="add-btn">
                        <button class="btn btn-sm btn-outline-dark">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                </h2>
                <div
                cdkDropList
                [cdkDropListData]="completed"
                class="list-items-group"
                (cdkDropListDropped)="drop($event)">
                    <div class="list-item" *ngFor="let item of completed" cdkDrag cdkDragBoundary=".board">
                        <div class="title">
                            {{item}} - Title of the task goes here
                        </div>
                        <div class="assignee">
                            <img src="../../assets/icons/user.png" alt="">
                            <span class="assignee__name">John Doe</span>
                        </div>
                        <div class="action">
                            <i class="fas fa-ellipsis-h"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 list-type-container">
                <h2 class="heading">
                    Closed
                    <span class="count-badge badge badge-dark">
                        4
                    </span>
                    <span class="add-btn">
                        <button class="btn btn-sm btn-outline-dark">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                </h2>
                <div
                cdkDropList
                [cdkDropListData]="closed"
                class="list-items-group"
                (cdkDropListDropped)="drop($event)">
                    <div class="list-item" *ngFor="let item of closed" cdkDrag cdkDragBoundary=".board">
                        <div class="title">
                            {{item}} - Title of the task goes here
                        </div>
                        <div class="assignee">
                            <img src="../../assets/icons/user.png" alt="">
                            <span class="assignee__name">John Doe</span>
                        </div>
                        <div class="action">
                            <i class="fas fa-ellipsis-h"></i>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        <!-- Board Ends -->
    </div>

</main>