<app-sidebar></app-sidebar>
<main [ngClass]="{ 'main-collapsed': isCollapsed }">
  <!-- <app-header></app-header> -->
  <div class="col-lg-12 page-header-wrapper clearfix">
    <div class="col-lg-6 breacrumbs-wrapper float-left">
      <span class="page-title">Projects</span>
    </div>
    <div class="col-lg-6 action-wrapper float-left text-right">
      <button
        class="btn t-primary-dark"
        (click)="openProjectModal(projectContent)"
      >
        <i class="fa fa-plus mr-1"></i> Create New Project
      </button>
    </div>
  </div>
  <div class="col-lg-12 main-container clearfix">
    <!-- Project Table Begins -->
    <!-- <app-empty-state></app-empty-state> -->
    <div class="t-card">
      <div class="col-lg-12 d-flex justify-content-between flex-row filters">
        <div class="col-lg-4 input-group pl-0 filters__search">
          <input type="search" class="form-control" placeholder="Search" />
        </div>
        <div class="col-lg-2 filters__dropdown--search">
          <select class="form-control">
            <optgroup label="Choose Project Status">
              <option value="">Active</option>
              <option value="">Past</option>
            </optgroup>
          </select>
        </div>
        <div class="col-lg-2 filters__dropdown--search">
          <select class="form-control">
            <optgroup label="Choose Client">
              <option value="">John Doe</option>
            </optgroup>
          </select>
        </div>
      </div>
      <table class="table table-responsive-lg table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Client</th>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let project of projects">
              <td>{{ project.name }}</td>
              <td>{{ project?.clientData?.name }}</td>

              <td>
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-sm btn-dark" ngbDropdownToggle>
                    <i class="fa fa-edit"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="Product Actions">
                    <button
                      ngbDropdownItem
                      (click)="openProjectModal(projectContent, project)"
                    >
                      Edit
                    </button>
                    <button ngbDropdownItem>Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!-- Project Table Ends -->
  </div>
</main>

<!-- Project Modal Begins -->
<ng-template #projectContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="!selectedProject">
      Create new project
    </h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="selectedProject">
      Update project
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="projectForm">
    <div class="modal-body">
      <div class="form-group">
        <label>Project Name</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-box"></i></span>
          </div>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            required
          />
        </div>
      </div>
      <field-errors
        [fieldName]="'name'"
        [formGroup]="projectForm"
        [getErrorMessage]="getErrorMessage.bind(this)"
      ></field-errors>
      <div class="form-group">
        <label>Associated Client</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fas fa-user-tie"></i
            ></span>
          </div>
          <select formControlName="clientRef" class="form-control">
            <option *ngFor="let client of clients" [value]="client.$id">
              {{ client.name }}
            </option>
          </select>
        </div>

        <field-errors
          [fieldName]="'clientRef'"
          [formGroup]="projectForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onAddProject()"
      *ngIf="!selectedProject"
    >
      CREATE
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onUpdateProject()"
      *ngIf="selectedProject"
    >
      UPDATE
    </button>
  </div>
</ng-template>
<!-- Project Modal Ends -->
