import { Component, OnInit, inject } from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';
import { Mixin } from 'ts-mixer';
import { IReactiveForm } from 'src/core/helpers/ireactive-form';
import { ProjectClass, ProjectList } from 'src/core/services/project.class';
import { ALL_FIELDS_REQUIRED } from 'src/core/constants/message.constants';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent
  extends Mixin(IReactiveForm, ProjectClass)
  implements OnInit
{
  isCollapsed;
  sidebarService = inject(SidebarService);
  projectForm = this.projectFG;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fetchClients();
    this.fetchProjects();
    this.sidebarService.isCollapsed.subscribe(
      (collapsedState) => (this.isCollapsed = collapsedState)
    );
  }

  openProjectModal(content, project?: ProjectList) {
    if (!project) {
      this.selectedProject = undefined;
      this.projectForm.reset();
    } else if (project) {
      this.selectedProject = project;
      this.projectForm.patchValue(project);
    }

    this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static',
      centered: true,
    });
  }

  onAddProject() {
    this._formService.markFormAsTouched(this.projectForm);

    if (!this.projectForm.valid) {
      this._toastService.showError(ALL_FIELDS_REQUIRED);
      return;
    }

    this.createProject(this.projectForm.value);
  }

  onUpdateProject() {
    this._formService.markFormAsTouched(this.projectForm);

    if (!this.projectForm.valid) {
      this._toastService.showError(ALL_FIELDS_REQUIRED);
      return;
    }

    this.updateProject(this.projectForm.value);
  }
}
