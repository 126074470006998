import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public isCollapsed: BehaviorSubject<boolean>;
  constructor() {
    this.isCollapsed = new BehaviorSubject(false);
  }

  getCollapseState(): Observable<boolean> {
    return this.isCollapsed.asObservable();
  }
}
