<app-sidebar></app-sidebar>
<main [ngClass]="{ 'main-collapsed': isCollapsed }">
  <!-- <app-header></app-header> -->
  <div class="col-lg-12 page-header-wrapper clearfix">
    <div class="col-lg-6 breacrumbs-wrapper float-left">
      <span class="page-title">Time Management</span>
    </div>
    <div class="col-lg-6 action-wrapper float-left text-right">
      <button
        class="btn t-primary-dark"
        (click)="openEntryModal(entryContent, 'ADD')"
      >
        <i class="fa fa-plus mr-1"></i> Add New Entry
      </button>
    </div>
  </div>
  <div class="col-lg-12 main-container clearfix">
    <!-- Time Management Table Begins -->
    <!-- <app-empty-state></app-empty-state> -->

    <!-- Filters Begins -->
    <!-- <div class="t-card">
      <div class="col-lg-12 d-flex justify-content-between flex-row filters">
        <div class="col-lg-4 input-group pl-0 filters__search">
          <input type="search" class="form-control" placeholder="Search" />
        </div>
        <div class="col-lg-2 filters__dropdown--search">
          <select class="form-control">
            <optgroup label="Choose Project">
              <option value="">All</option>
              <option value="">Project 1</option>
              <option value="">Project 2</option>
            </optgroup>
          </select>
        </div>
        <div class="col-lg-2 filters__dropdown--search">
          <select class="form-control">
            <optgroup label="Choose Team Member">
              <option value="">All</option>
              <option value="">John Doe</option>
              <option value="">Jim Seth</option>
            </optgroup>
          </select>
        </div>
      </div>
    </div> -->
    <!-- Filters Ends -->

    <!-- Single Day Entry Begins -->
    <div class="t-card">
      <!-- <div class="t-card" *ngFor="let day of ['1', '2', '3', '4']"> -->
      <table class="table table-responsive-lg table-hover">
        <thead class="thead-light">
          <tr>
            <th scope="col" colspan="3">Today's Log</th>
            <th scope="col">
              <small>Total:</small> {{ totalWorkHours }} Hours
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of entries">
            <td>{{ entry.taskDescription }}</td>
            <td>{{ entry.projectData.name }}</td>
            <td>{{ entry.startTime }} - {{ entry.endTime }}</td>
            <td>
              <strong>{{ entry.workHours }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Single Day Entry Ends -->

    <!-- Time Management Table Ends -->
  </div>
</main>

<!-- Client Modal Begins -->
<ng-template #entryContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="!isEdit">
      Add new entry
    </h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="isEdit">
      Update entry
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="timeManagementForm">
    <div class="modal-body">
      <div class="form-group col-lg-6 float-left">
        <label>Project</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-user"></i></span>
          </div>
          <select formControlName="projectRef" class="form-control">
            <option *ngFor="let project of projects" [value]="project.$id">
              {{ project.name }} - {{ project.clientData.name }}
            </option>
          </select>
        </div>
        <field-errors
          [fieldName]="'projectRef'"
          [formGroup]="timeManagementForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <!-- <div class="form-group col-lg-6 float-left">
          <label>Date</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-calendar"></i></span>
            </div>
            <input
              ngbDatepicker
              #d="ngbDatepicker"
              (click)="d.toggle()"
              class="form-control"
              required
            />
          </div>
        </div> -->
      <div class="form-group col-lg-4 float-left">
        <label>Start Time</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-clock"></i></span>
          </div>
          <!-- <input type="text" class="form-control" required /> -->
          <ngb-timepicker
            formControlName="startTimeD"
            [spinners]="true"
          ></ngb-timepicker>
        </div>
        <field-errors
          [fieldName]="'startTimeD'"
          [formGroup]="timeManagementForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group col-lg-4 float-left">
        <label>End Time</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-clock"></i></span>
          </div>
          <!-- <input type="text" class="form-control" required /> -->
          <ngb-timepicker
            formControlName="endTimeD"
            [spinners]="true"
          ></ngb-timepicker>
        </div>
        <field-errors
          [fieldName]="'endTimeD'"
          [formGroup]="timeManagementForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <!-- <div class="form-group col-lg-4 float-left">
        <label>Total Work Hours</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-clock"></i></span>
          </div>
          <input type="number" class="form-control" readonly />
        </div>
      </div> -->
      <div class="form-group col-lg-12 float-left">
        <label>Task Description</label>
        <div class="input-group">
          <textarea
            formControlName="taskDescription"
            rows="5"
            class="w-100"
          ></textarea>
        </div>
        <field-errors
          [fieldName]="'taskDescription'"
          [formGroup]="timeManagementForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onAddEntry()"
      *ngIf="!isEdit"
    >
      CREATE
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onUpdateEntry()"
      *ngIf="isEdit"
    >
      UPDATE
    </button>
  </div>
</ng-template>
<!-- Client Modal Ends -->
