import { inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FormErrorService } from '../services/form-error.service';
import { FormService } from '../services/form.service';
import { MOBILE_REGEX, PASSWORD_REGEX } from '../constants/regex.constant';

export class IReactiveForm {
  public _formBuilder = inject(FormBuilder);
  public _formErrorService = inject(FormErrorService);
  public _formService = inject(FormService);

  /**
   * This function will return form for team-member component.
   * @author Technomize
   * @return {FormGroup} Return a FormGroup
   **/
  get teamMemberFG(): FormGroup {
    return this._formBuilder.group({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(PASSWORD_REGEX),
      ]),
      name: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [
        Validators.required,
        Validators.pattern(MOBILE_REGEX),
      ]),
      gitUserName: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * This function will return form for client component.
   * @author Technomize
   * @return {FormGroup} Return a FormGroup
   **/
  get clientFG(): FormGroup {
    return this._formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      mobile: new FormControl(null, [
        Validators.required,
        Validators.pattern(MOBILE_REGEX),
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  /**
   * This function will return form for project component.
   * @author Technomize
   * @return {FormGroup} Return a FormGroup
   **/
  get projectFG(): FormGroup {
    return this._formBuilder.group({
      clientRef: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
    });
  }

  /**
   * This function will return form for time-management component.
   * @author Technomize
   * @return {FormGroup} Return a FormGroup
   **/
  get timeManagementFG(): FormGroup {
    return this._formBuilder.group({
      projectRef: new FormControl(null, [Validators.required]),
      startTimeD: new FormControl(null, [Validators.required]),
      endTimeD: new FormControl(null, [Validators.required]),
      taskDescription: new FormControl(null, [Validators.required]),
    });
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
