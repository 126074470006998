<app-sidebar></app-sidebar>
<main [ngClass]="{ 'main-collapsed': isCollapsed }">
  <!-- <app-header></app-header> -->
  <div class="col-lg-12 page-header-wrapper clearfix">
    <div class="col-lg-6 breacrumbs-wrapper float-left">
      <span class="page-title">Clients</span>
    </div>
    <div class="col-lg-6 action-wrapper float-left text-right">
      <button
        class="btn t-primary-dark"
        (click)="openClientModal(clientContent)"
      >
        <i class="fa fa-plus mr-1"></i> Add New Client
      </button>
    </div>
  </div>
  <div class="col-lg-12 main-container clearfix">
    <!-- Client Table Begins -->
    <!-- <app-empty-state></app-empty-state> -->
    <div class="t-card">
      <div class="col-lg-12 d-flex justify-content-between flex-row filters">
        <div class="col-lg-4 input-group pl-0 filters__search">
          <input type="search" class="form-control" placeholder="Search" />
        </div>
        <div class="col-lg-2 filters__dropdown--search">
          <select class="form-control">
            <optgroup label="Choose Project Status">
              <option value="">All</option>
              <option value="">Archieved</option>
            </optgroup>
          </select>
        </div>
      </div>
      <table class="table table-responsive-lg table-hover">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Email</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let client of clients">
              <td>{{ client.name }}</td>
              <td>{{ client.mobile }}</td>
              <td>{{ client.email }}</td>
              <td>
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-sm btn-dark" ngbDropdownToggle>
                    <i class="fa fa-edit"></i>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="Product Actions">
                    <button
                      ngbDropdownItem
                      (click)="openClientModal(clientContent, client)"
                    >
                      Edit
                    </button>
                    <!-- <button ngbDropdownItem>Archieve</button> -->
                    <button ngbDropdownItem (click)="onDeleteClient(client)">
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!-- Client Table Ends -->
  </div>
</main>

<!-- Client Modal Begins -->
<ng-template #clientContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" *ngIf="!selectedClient">
      Add new Client
    </h4>
    <h4 class="modal-title" id="modal-basic-title" *ngIf="selectedClient">
      Update Client
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="clientForm">
    <div class="modal-body">
      <div class="form-group">
        <label>Client Name</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-user"></i></span>
          </div>
          <input
            formControlName="name"
            type="text"
            class="form-control"
            required
          />
        </div>
        <field-errors
          [fieldName]="'name'"
          [formGroup]="clientForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group">
        <label>Mobile</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="fa fa-phone-alt"></i
            ></span>
          </div>
          <input
            type="tel"
            class="form-control"
            formControlName="mobile"
            required
          />
        </div>
        <field-errors
          [fieldName]="'mobile'"
          [formGroup]="clientForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
      <div class="form-group">
        <label>Email</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-envelope"></i></span>
          </div>
          <input
            formControlName="email"
            type="email"
            class="form-control"
            required
          />
        </div>
        <field-errors
          [fieldName]="'email'"
          [formGroup]="clientForm"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onAddClient()"
      *ngIf="!selectedClient"
    >
      CREATE
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      (click)="onUpdateClient()"
      *ngIf="selectedClient"
    >
      UPDATE
    </button>
  </div>
</ng-template>
<!-- Client Modal Ends -->
