import { Mixin } from 'ts-mixer';
import { DatabaseService } from './database.service';
import { ProjectClass, ProjectList } from './project.class';
import {
  DATABASE_ID,
  ENTRIES_COLLECTION_ID,
  PROJECT_COLLECTION_ID,
  USER_STORAGE_ID,
} from '../constants/database.constant';
import { ID, Query } from 'appwrite';
import * as moment from 'moment';
import {
  ENTRY_CREATED_SUCCESS,
  START_AND_END_DIFF,
} from '../constants/message.constants';

export interface TimeManagement {
  createTimeEntry(body: CreateTimeEntryBody): void;

  fetchEntries(): void;
}

export interface CreateTimeEntryBody {
  startTimeD: {
    hour: number;
    minute: number;
    second: number;
  };
  endTimeD: {
    hour: number;
    minute: number;
    second: number;
  };
  startTime: string;
  endTime: string;
  taskDescription: string;
  projectRef: string;
  userRef: string;
  date?: string;
  workHours?: string;
}

export class TimeManagementClass
  extends Mixin(DatabaseService, ProjectClass)
  implements TimeManagement
{
  entries: EntriesList[] = [] as EntriesList[];
  totalWorkHours: number = 0;

  async createTimeEntry(body: CreateTimeEntryBody): Promise<void> {
    body.workHours = (
      moment(`${body.endTimeD.hour}:${body.endTimeD.minute}`, 'HH:mm').diff(
        moment(`${body.startTimeD.hour}:${body.startTimeD.minute}`, 'HH:mm'),
        'minutes'
      ) / 60
    ).toFixed(2);

    body.date = moment().format('YYYY-MM-DD');
    body.userRef = localStorage.getItem(USER_STORAGE_ID);
    body.startTime = `${body.startTimeD.hour}:${body.startTimeD.minute}`;
    body.endTime = `${body.endTimeD.hour}:${body.endTimeD.minute}`;

    if (+body.workHours < 0) {
      this._toastService.showError(START_AND_END_DIFF);
      return;
    }
    delete body.startTimeD;
    delete body.endTimeD;
    try {
      await this.database.createDocument(
        DATABASE_ID,
        ENTRIES_COLLECTION_ID,
        ID.unique(),
        body
      );

      this.modalService.dismissAll();
      this._toastService.showSuccess(ENTRY_CREATED_SUCCESS);
      this.fetchEntries();
    } catch (e) {
      this._toastService.showError(e.message);
    }
  }

  async fetchEntries(): Promise<void> {
    try {
      let res = await this.database.listDocuments(
        DATABASE_ID,
        ENTRIES_COLLECTION_ID,
        [
          Query.equal('userRef', localStorage.getItem(USER_STORAGE_ID)),
          Query.equal('date', moment().format('YYYY-MM-DD')),
        ]
      );

      let projectRes = await this.database.listDocuments(
        DATABASE_ID,
        PROJECT_COLLECTION_ID
      );

      this.entries = this.entriesWithProjects(
        res.documents as EntriesList[],
        projectRes.documents as ProjectList[]
      );

      this.setTotalWorkHours();
    } catch (e) {
      this._toastService.showError(e);
    }
  }

  private setTotalWorkHours() {
    this.entries.forEach((entry) => {
      (this.totalWorkHours += +entry.workHours).toFixed(2);
    });
  }
}

export interface EntriesResponse {
  total: number;
  documents: EntriesList[];
}

export interface EntriesList {
  projectRef: string;
  startTime: string;
  endTime: string;
  taskDescription: string;
  date: string;
  userRef: string;
  workHours: string;
  $id: string;
  $createdAt: string;
  $updatedAt: string;
  $permissions: string[];
  $collectionId: string;
  $databaseId: string;
  projectData?: ProjectList;
}
