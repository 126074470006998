import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormErrorService {
  constructor() {}

  getErrorMessage(fieldName: string, errorName: string): string {
    switch (errorName) {
      case 'pattern':
        if (fieldName == 'mobileNumber') {
          return 'Invalid mobile number';
        }
        if (fieldName === 'email') {
          return 'Invalid email';
        }
        if (fieldName === 'newPassword') {
          return 'Password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters';
        }
        return 'Invalid value';
      case 'required':
        return 'This field is required';
      case 'minlength':
        if (fieldName == 'password') {
          return 'Password length should be 8 digits';
        }
        if (fieldName == 'aadharId') {
          return 'Aadhar length should be 12 digits';
        }
        return 'Minimum length not fulfilled';
      case 'maxlength':
        if (fieldName == 'aadharId') {
          return 'Aadhar length should be 12 digits';
        }
        return 'Minimum length not fulfilled';
      case 'email':
        return 'Invalid email id';
      case 'mustMatch':
        return 'Entered password and confirm password is different';
      default:
        return 'Unhandled error for - ' + errorName;
    }
  }
}
