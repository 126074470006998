import { Component, OnInit, inject } from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mixin } from 'ts-mixer';
import { IReactiveForm } from 'src/core/helpers/ireactive-form';
import { TimeManagementClass } from 'src/core/services/time-management.class';
import { ALL_FIELDS_REQUIRED } from 'src/core/constants/message.constants';

@Component({
  selector: 'app-time-management',
  templateUrl: './time-management.component.html',
  styleUrls: ['./time-management.component.scss'],
})
export class TimeManagementComponent
  extends Mixin(IReactiveForm, TimeManagementClass)
  implements OnInit
{
  isCollapsed;
  isEdit;
  time;
  sidebarService = inject(SidebarService);
  modalService = inject(NgbModal);
  timeManagementForm = this.timeManagementFG;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.sidebarService.isCollapsed.subscribe(
      (collapsedState) => (this.isCollapsed = collapsedState)
    );
    this.fetchProjects();
    this.fetchEntries();
  }

  openEntryModal(content, action) {
    if (action === 'ADD') {
      this.isEdit = false;
    } else if (action === 'EDIT') {
      this.isEdit = true;
    }
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    });
  }

  onAddEntry() {
    this._formService.markFormAsTouched(this.timeManagementForm);

    if (!this.timeManagementForm.valid) {
      this._toastService.showError(ALL_FIELDS_REQUIRED);
      return;
    }

    this.createTimeEntry(this.timeManagementForm.value);
  }

  onUpdateEntry() {}
}
