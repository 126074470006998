import { ID } from 'appwrite';
import {
  CLIENT_COLLECTION_ID,
  DATABASE_ID,
} from '../constants/database.constant';
import { DatabaseService } from './database.service';
import { inject } from '@angular/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastService } from './toast.service';
import {
  CLIENT_DELETE_SUCCESS,
  CLIENT_SUCCESS,
  CLIENT_UPDATE_SUCCESS,
} from '../constants/message.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface Client {
  addClient(body: CreateClient): void;

  updateClient(body: CreateClient): void;

  fetchClients(): void;

  deleteClient(): void;
}

export interface CreateClient {
  name: string;
  mobile: string;
  email: string;
}

export class ClientClass extends DatabaseService implements Client {
  _spinner = inject(SpinnerVisibilityService);
  _toastService = inject(ToastService);
  modalService = inject(NgbModal);

  public clients: ClientList[] = [] as ClientList[];
  public selectedClient: ClientList;

  constructor() {
    super();
  }

  addClient(body: CreateClient): void {
    this._spinner.show();
    this.database
      .createDocument(DATABASE_ID, CLIENT_COLLECTION_ID, ID.unique(), body)
      .then((res) => {
        this._toastService.showSuccess(CLIENT_SUCCESS);
        this.modalService.dismissAll();
        this.fetchClients();
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
      });
  }

  updateClient(body: CreateClient): void {
    this._spinner.show();
    this.database
      .updateDocument(
        DATABASE_ID,
        CLIENT_COLLECTION_ID,
        this.selectedClient.$id,
        body
      )
      .then((res) => {
        this._toastService.showSuccess(CLIENT_UPDATE_SUCCESS);
        this.modalService.dismissAll();
        this.fetchClients();
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
      });
  }

  fetchClients(): void {
    this._spinner.show();
    this.database
      .listDocuments(DATABASE_ID, CLIENT_COLLECTION_ID)
      .then((res: ClientFetchRes) => {
        this.clients = res.documents;
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
      });
  }

  deleteClient() {
    this._spinner.show();
    this.database
      .deleteDocument(
        DATABASE_ID,
        CLIENT_COLLECTION_ID,
        this.selectedClient.$id
      )
      .then((res) => {
        this._toastService.showSuccess(CLIENT_DELETE_SUCCESS);
        this.fetchClients();
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
        this.modalService.dismissAll();
      });
  }
}

export interface ClientFetchRes {
  total: number;
  documents: ClientList[];
}

export interface ClientList {
  name: string;
  mobile: string;
  email: string;
  $id: string;
  $createdAt: string;
  $updatedAt: string;
  $permissions: string[];
  $collectionId: string;
  $databaseId: string;
}
