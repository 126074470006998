import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FormErrorService } from 'src/core/services/form-error.service';
import { FormService } from 'src/core/services/form.service';
import { UserService } from 'src/core/services/user.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  showPassword = false;
  showError = true;
  _formBuilder = inject(FormBuilder);
  _formService = inject(FormService);
  _formErrorService = inject(FormErrorService);
  loginFG!: FormGroup;

  _userService = inject(UserService);

  constructor(private router: Router) {}

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginFG = this._formBuilder.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  onDoLogin() {
    this._userService.logInAccount(this.loginFG.value);
  }

  togglePasswordView() {
    this.showPassword = !this.showPassword;
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }
}
