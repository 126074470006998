import { Component, OnInit, inject } from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IReactiveForm } from 'src/core/helpers/ireactive-form';
import { TeamMember } from 'src/core/services/team.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent extends IReactiveForm implements OnInit {
  isCollapsed;
  isEdit;

  sidebarService = inject(SidebarService);
  modalService = inject(NgbModal);
  createTeamMemberForm = this.teamMemberFG;

  ngOnInit(): void {
    this.sidebarService.isCollapsed.subscribe(
      (collapsedState) => (this.isCollapsed = collapsedState)
    );
  }

  fetchTeamMembers() {}

  openTeamModal(content, action) {
    if (action === 'ADD') {
      this.isEdit = false;
    } else if (action === 'EDIT') {
      this.isEdit = true;
    }
    this.modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    });
  }

  addMember(): void {}

  editMember(): void {}
}
