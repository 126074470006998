import { Component, OnInit, inject } from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IReactiveForm } from 'src/core/helpers/ireactive-form';
import { ClientClass, ClientList } from 'src/core/services/client.service';
import { Mixin } from 'ts-mixer';
import {
  ALL_FIELDS_REQUIRED,
  CLIENT_DEL_CONFIRM,
} from 'src/core/constants/message.constants';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent
  extends Mixin(IReactiveForm, ClientClass)
  implements OnInit
{
  isCollapsed;
  sidebarService = inject(SidebarService);
  clientForm = this.clientFG;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.sidebarService.isCollapsed.subscribe(
      (collapsedState) => (this.isCollapsed = collapsedState)
    );

    this.fetchClients();
  }

  openClientModal(content, client?: ClientList) {
    if (!client) {
      this.selectedClient = undefined;
      this.clientForm.reset();
    } else if (client) {
      this.selectedClient = client;
      this.clientForm.patchValue(client);
    }
    this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static',
      centered: true,
    });
  }

  onAddClient() {
    this._formService.markFormAsTouched(this.clientForm);

    if (!this.clientForm.valid) {
      this._toastService.showError(ALL_FIELDS_REQUIRED);
      return;
    }

    this.addClient(this.clientForm.value);
  }

  onUpdateClient() {
    this._formService.markFormAsTouched(this.clientForm);

    if (!this.clientForm.valid) {
      this._toastService.showError(ALL_FIELDS_REQUIRED);
      return;
    }

    this.updateClient(this.clientForm.value);
  }

  onDeleteClient(client: ClientList) {
    this.selectedClient = client;
    let res = confirm(CLIENT_DEL_CONFIRM);

    if (!res) {
      this.selectedClient = undefined;
      return;
    }

    this.deleteClient();
  }
}
