import { Injectable, inject } from '@angular/core';
import { DatabaseService } from './database.service';
import { Router } from '@angular/router';
import { ToastService } from './toast.service';
import { LOGIN_SUCCESS } from '../constants/message.constants';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { USER_STORAGE_ID } from '../constants/database.constant';

@Injectable({ providedIn: 'root' })
export class UserService {
  _databaseService = inject(DatabaseService);
  router = inject(Router);
  _toastService = inject(ToastService);
  _spinner = inject(SpinnerVisibilityService);

  createAccount() {}

  async logInAccount(body: { email: string; password: string }) {
    try {
      this._spinner.show();
      let res = await this._databaseService.databaseAccount.createEmailSession(
        body.email,
        body.password
      );
      localStorage.setItem(USER_STORAGE_ID, res.userId);
      this._toastService.showSuccess(LOGIN_SUCCESS);
      this.router.navigateByUrl('/home');
    } catch (e) {
      this._toastService.showError(e.message);
    } finally {
      this._spinner.hide();
    }
  }
}
