import { Injectable } from '@angular/core';
import { Account, Client, Databases } from 'appwrite';
import { END_POINT, PROJECT_ID } from '../constants/database.constant';
import { ProjectList } from './project.class';
import { ClientList } from './client.service';
import { EntriesList } from './time-management.class';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private client = new Client();
  private account!: Account;
  private databases!: Databases;

  constructor() {
    this.client.setEndpoint(END_POINT).setProject(PROJECT_ID);
    this.account = new Account(this.client);
    this.databases = new Databases(this.client);
  }

  get databaseAccount(): Account {
    this.account = new Account(this.client);
    return this.account;
  }

  get databaseClient(): Client {
    return this.client;
  }

  get database(): Databases {
    return this.databases;
  }

  projectsWithClients(
    projects: ProjectList[],
    clients: ClientList[]
  ): ProjectList[] {
    projects.forEach((project) => {
      clients.forEach((client) => {
        if (project.clientRef === client.$id) {
          project.clientData = client;
        }
      });
    });
    return projects;
  }

  entriesWithProjects(
    entries: EntriesList[],
    projects: ProjectList[]
  ): EntriesList[] {
    entries.forEach((entry) => {
      projects.forEach((project) => {
        if (entry.projectRef === project.$id) {
          entry.projectData = project;
        }
      });
    });
    return entries;
  }
}
