<form [formGroup]="loginFG">
  <section class="login">
    <div
      class="col-lg-4 offset-lg-4 col-md-6 offset-md-2 form-wrapper clearfix"
    >
      <div class="col-lg-12 section-heading">
        <p>Welcome to Technomize</p>
      </div>

      <div class="col-lg-12 input-wrapper clearfix">
        <label for="userName">Username</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fa fa-user"></i
            ></span>
          </div>
          <input
            formControlName="email"
            id="userName"
            type="text"
            class="form-control"
            aria-label="Username"
            aria-describedby="Username"
          />
        </div>
        <field-errors
          [fieldName]="'email'"
          [formGroup]="loginFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>

      <div class="col-lg-12 input-wrapper clearfix">
        <label for="password">Password</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"
              ><i class="fa fa-lock"></i
            ></span>
          </div>
          <input
            id="password"
            formControlName="password"
            [type]="showPassword ? 'text' : 'password'"
            name="password"
            class="form-control"
            aria-label="Password"
            aria-describedby="Password"
          />
          <div class="input-group-append" (click)="togglePasswordView()">
            <span class="input-group-text" *ngIf="showPassword">
              <i class="fa fa-eye"></i>
            </span>
            <span class="input-group-text" *ngIf="!showPassword">
              <i class="fa fa-eye-slash"></i>
            </span>
          </div>
        </div>
        <field-errors
          [fieldName]="'password'"
          [formGroup]="loginFG"
          [getErrorMessage]="getErrorMessage.bind(this)"
        ></field-errors>
      </div>

      <div class="col-lg-12 input-wrapper center-item-wrapper clearfix">
        <button
          type="submit"
          class="btn btn-sm login-btn hover-shadow"
          (click)="onDoLogin()"
        >
          LOGIN
        </button>
      </div>
    </div>
  </section>
</form>
