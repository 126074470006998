import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../shared/services/sidebar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isCollapsed;
  constructor(private sidebarService: SidebarService) {
    this.sidebarService.isCollapsed.subscribe((collapsedState) => this.isCollapsed = collapsedState);
  }

  ngOnInit(): void {
  }

}
