import { Component, OnInit, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SidebarService } from '../../services/sidebar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  sidebarIsCollapsed = false;
  router = inject(Router);
  constructor(
    private modalService: NgbModal,
    private sidebarService: SidebarService
  ) {}

  ngOnInit(): void {}

  openModal(content, ariaLabel) {
    this.modalService.open(content, {
      ariaLabelledBy: ariaLabel,
      centered: true,
    });
  }

  onLogOut() {
    localStorage.clear();
    this.modalService.dismissAll();
    this.router.navigateByUrl('/');
  }

  toggleSidebar() {
    this.sidebarIsCollapsed = !this.sidebarIsCollapsed;
    this.sidebarService.isCollapsed.next(this.sidebarIsCollapsed);
  }
}
