import { inject } from '@angular/core';
import { DatabaseService } from './database.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastService } from './toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CLIENT_COLLECTION_ID,
  DATABASE_ID,
  PROJECT_COLLECTION_ID,
} from '../constants/database.constant';
import { ID } from 'appwrite';
import { Mixin } from 'ts-mixer';
import { ClientClass, ClientList } from './client.service';
import {
  PROJECT_CREATE_SUCCESS,
  PROJECT_UPDATE_SUCCESS,
} from '../constants/message.constants';

export interface Project {
  createProject(body: CreateProjectBody): void;

  updateProject(body: CreateProjectBody): void;

  fetchProjects(): void;
}

export interface CreateProjectBody {
  clientRef: string;
  name: string;
}

export class ProjectClass
  extends Mixin(DatabaseService, ClientClass)
  implements Project
{
  _spinner = inject(SpinnerVisibilityService);
  _toastService = inject(ToastService);
  modalService = inject(NgbModal);
  projects: ProjectList[] = [] as ProjectList[];
  selectedProject: ProjectList;

  createProject(body: CreateProjectBody): void {
    this._spinner.show();
    this.database
      .createDocument(DATABASE_ID, PROJECT_COLLECTION_ID, ID.unique(), body)
      .then((res) => {
        this.fetchProjects();
        this.modalService.dismissAll();
        this._toastService.showSuccess(PROJECT_CREATE_SUCCESS);
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
      });
  }

  updateProject(body: CreateProjectBody): void {
    this._spinner.show();
    this.database
      .updateDocument(
        DATABASE_ID,
        PROJECT_COLLECTION_ID,
        this.selectedProject.$id,
        body
      )
      .then((res) => {
        this.fetchProjects();
        this.modalService.dismissAll();
        this._toastService.showSuccess(PROJECT_UPDATE_SUCCESS);
      })
      .catch((err) => {
        this._toastService.showError(err.message);
      })
      .finally(() => {
        this._spinner.hide();
      });
  }

  async fetchProjects(): Promise<void> {
    this._spinner.show();

    try {
      let clientRes = await this.database.listDocuments(
        DATABASE_ID,
        CLIENT_COLLECTION_ID
      );

      let projectRes = await this.database.listDocuments(
        DATABASE_ID,
        PROJECT_COLLECTION_ID
      );

      this.projects = this.projectsWithClients(
        projectRes.documents as ProjectList[],
        clientRes.documents as ClientList[]
      );
    } catch (e) {
      this._toastService.showError(e.message);
    } finally {
      this._spinner.hide();
    }
  }
}

export interface ProjectFetchRes {
  total: number;
  documents: ProjectList[];
}

export interface ProjectList {
  name: string;
  clientRef: string;
  $id: string;
  $createdAt: string;
  $updatedAt: string;
  $permissions: string[];
  $collectionId: string;
  $databaseId: string;
  clientData?: ClientList;
}
