import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TeamComponent } from './team/team.component';
import { ClientsComponent } from './clients/clients.component';
import { ProjectsComponent } from './projects/projects.component';
import { BoardComponent } from './board/board.component';
import { FinancesComponent } from './finances/finances.component';
import { TimeManagementComponent } from './time-management/time-management.component';
import { ReportsComponent } from './reports/reports.component';
import { FilesComponent } from './files/files.component';
import { FullscreenLoaderComponent } from './shared/components/fullscreen-loader/fullscreen-loader.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { CustomizationsComponent } from './customizations/customizations.component';
import { EmptyStateComponent } from './shared/components/empty-state/empty-state.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastComponent } from 'src/shared/components/toast/toast.component';
import { FieldErrorsComponent } from 'src/shared/components/field-errors/field-errors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';

@NgModule({
  declarations: [
    AppComponent,
    TeamComponent,
    ClientsComponent,
    ProjectsComponent,
    BoardComponent,
    FinancesComponent,
    TimeManagementComponent,
    ReportsComponent,
    FilesComponent,
    FullscreenLoaderComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    AuthComponent,
    HomeComponent,
    CustomizationsComponent,
    EmptyStateComponent,
    ToastComponent,
    FieldErrorsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    DragDropModule,
    ReactiveFormsModule,
    FormsModule,
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
