<app-sidebar></app-sidebar>
<main [ngClass]="{'main-collapsed': isCollapsed}">
    <app-header></app-header>

    <!--loading -->
    <!-- <app-fullscreen-loader></app-fullscreen-loader> -->
    <!--loading -->

    <div class="col-lg-12 main-wrapper clearfix">
        <div class="col-lg-12 section-title">
            <p>Overview</p>
        </div>

        <div class="card-deck">
            <div class="dash-card-wrapper col-lg-3 float-left">
                <div class="card text-center">
                    <!-- <img src="../../assets/empty.svg" class="card-img-top" alt="..."> -->
                    <div class="card-body">
                      <h5 class="card-title">5</h5>
                      <p class="card-text">Active Projects</p>
                    </div>
                </div>
            </div>
            <div class="dash-card-wrapper col-lg-3 float-left">
                <div class="card text-center">
                    <!-- <img src="../../assets/empty.svg" class="card-img-top" alt="..."> -->
                    <div class="card-body">
                      <h5 class="card-title">18</h5>
                      <p class="card-text">Work Hours</p>
                    </div>
                </div>
            </div>
            <div class="dash-card-wrapper col-lg-3 float-left">
                <div class="card text-center">
                    <!-- <img src="../../assets/empty.svg" class="card-img-top" alt="..."> -->
                    <div class="card-body">
                      <h5 class="card-title">12</h5>
                      <p class="card-text">Work Items</p>
                    </div>
                </div>
            </div>
            <div class="dash-card-wrapper col-lg-3 float-left">
                <div class="card text-center">
                    <!-- <img src="../../assets/empty.svg" class="card-img-top" alt="..."> -->
                    <div class="card-body">
                      <h5 class="card-title">00</h5>
                      <p class="card-text">Something Else</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Empty State -->
        <!-- <app-empty-state></app-empty-state> -->
        <!-- Empty State -->
    </div>
</main>
