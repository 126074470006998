<section [class]="sidebarIsCollapsed ? 'sidebar collapsed' : 'sidebar expanded'">
    <div class="sidebar-wrapper">
        <div class="sidebar-header-brand-wrapper clearfix">
            <div class="brand-inner float-left">
                <a class="sidebar-brand" [routerLink]="['/home']">
                    <!-- <img src="../../../../assets/images/yoyo-logo.png" alt="YOYO Foods Logo"> -->
                    <span>Technomize</span>
                </a>
                <!-- <span>
                    <i class="fa fa-angle-left"></i>
                </span> -->
            </div>
            <div class="toggle-btn float-left" (click)="toggleSidebar()">
                <span>
                    <i class="fa fa-angle-left"></i>
                </span>
            </div>
        </div>
        <ul>
            <li class="nav-link" [routerLink]="['/home']" routerLinkActive="router-link-active" title="Dashboard">
                <i class="fa fa-home"></i>
                <span>Dashboard</span>
            </li>
            <li class="nav-link" [routerLink]="['/board']" routerLinkActive="router-link-active" title="Board">
                <i class="fas fa-clipboard-list"></i>
                <span>Board</span>
            </li>
            <li class="nav-link" [routerLink]="['/time-management']" routerLinkActive="router-link-active" title="Time Management">
                <i class="fa fa-clock"></i>
                <span>Time Management</span>
            </li>
            <li class="nav-link" [routerLink]="['/clients']" routerLinkActive="router-link-active" title="Clients">
                <i class="fas fa-user-tie"></i>
                <span>Clients</span>
            </li>
            <li class="nav-link" [routerLink]="['/projects']" routerLinkActive="router-link-active" title="Projects">
                <i class="fa fa-desktop"></i>
                <span>Projects</span>
            </li>
            <li class="nav-link" [routerLink]="['/team']" routerLinkActive="router-link-active" title="Team">
                <i class="fa fa-users"></i>
                <span>Team</span>
            </li>
            <li class="nav-link" [routerLink]="['/finances']" routerLinkActive="router-link-active" title="Finances">
                <i class="fa fa-credit-card"></i>
                <span>Finances</span>
            </li>
            <li class="nav-link" [routerLink]="['/reports']" routerLinkActive="router-link-active" title="Reports">
                <i class="fas fa-chart-bar"></i>
                <span>Reports</span>
            </li>
            <li class="nav-link" [routerLink]="['/files']" routerLinkActive="router-link-active" title="Files">
                <i class="fas fa-file"></i>
                <span>Files</span>
            </li>
            <li class="nav-link" [routerLink]="['/customizations']" routerLinkActive="router-link-active" title="Customizations">
                <i class="fas fa-cog"></i>
                <span>Customizations</span>
            </li>
            <li class="nav-link" (click)="openModal(logoutContent, 'Logout-Modal')" title="Logout">
                <i class="fa fa-sign-out-alt"></i>
                <span>Logout</span>
            </li>
            <li class="nav-link branding">
                <div class="icon rounded-pill">U</div>
                <div class="info">
                    <a href="/home">
                      <p>Username</p>
                      <p>Admin</p>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</section>

<!-- Logout Modal -->
<ng-template #logoutContent let-modal>
    <div class="modal-body">
        <br>
      <p>Are you sure you want to Logout?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click) = "onLogOut()">Confirm</button>
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancel</button>
    </div>
</ng-template>
