export const LOGIN_SUCCESS = 'Welcome To Technomize-Portal';
export const CLIENT_SUCCESS = 'Client created successfully!';
export const CLIENT_UPDATE_SUCCESS = 'Client updated successfully!';
export const CLIENT_DELETE_SUCCESS = 'Client deleted successfully!';
export const ALL_FIELDS_REQUIRED = 'All fields are required!';
export const CLIENT_DEL_CONFIRM =
  'Are you sure you want to delete this client?';
export const PROJECT_CREATE_SUCCESS = 'Project created successfully!';
export const PROJECT_UPDATE_SUCCESS = 'Project updated successfullly!';
export const ENTRY_CREATED_SUCCESS = 'Entry created successfully!';
export const START_AND_END_DIFF =
  'End-Time should not be greater than Start-Time!';
